// import riot from 'riot'
// import $ from 'jquery'
// import 'feature.js'
// import 'whatwg-fetch'
// import Promise from 'promise-polyfill'
// import mojs from 'mo-js'
// import MojsPlayer from 'mojs-player'
// import jump from 'jump.js'
// import AOS from 'aos'
// import ScrollMagic from 'scrollmagic'
// import slick from 'slick-carousel'
// import p5 from 'p5'

// import './tags.js'

riot.mount('*')
AOS.init({
  duration: 1000
})

if (!feature.srcset) {
  document.querySelector('body').classList.add('is-ie')
}

;(function($){

$(() => {

  if(!feature.touch){
    $('a[href^="#"], .js-anchor-scroll').on('click', e => {
      e.preventDefault()
      let headerHeight = 80
      let $target = $(e.currentTarget.attributes.href.value)
      console.log($target)
      let targetOffset = $target.offset().top
      $("html,body").animate({
        scrollTop: targetOffset - headerHeight - 10
      }, 300)
      return false
    })
  }
})

})(jQuery)
